
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MotorcycleInsuranceForm from "@/components/reusable/MotorcycleInsuranceForm.vue";
import { Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useStore } from "vuex";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";

export default defineComponent({
  name: "truck-motor-insurance-selection",
  components: {
    Form,
    MotorcycleInsuranceForm,
  },
  props: {
    covers: Array,
  },
  data() {
    return {
      userRole: "",
      bodyTypes: VehicleBodyTypes.truck,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;
    const underwriters = ref([
      {
        id: "",
        logo: "",
        name: "",
        policyCount: 0,
        publicId: "",
        yearOfEstablishment: 1900,
      },
    ]);

    const vehicleBrands = ref([]);

    // const schema = Yup.object().shape({
    //       person: Yup.array().of(
    //         Yup.object().shape({
    //           name: Yup.string().required("Name is required."),
    //           hasInsurance: Yup.boolean().optional(),
    //           insuranceCompany: Yup.string().when("hasInsurance", {
    //             is: true,
    //             then: Yup.string().required(),
    //             otherwise: Yup.string().optional().nullable(),
    //           }),
    //         })
    //       ),
    //     });

    // contactPermission: yup
    //   .string()
    //   .oneOf(["Yes", "No"])
    //   .required("Please indicate your communications preference");

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .notRequired()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .notRequired()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .notRequired()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const saveComprehensive = (values, { resetForm }) => {
      console.clear();
      // console.table(JSON.stringify(values, null, 2));
      // console.log(values["package"], JwtService.getThirdPartyPM());

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (key === "vehicleImages") {
                  if (
                    typeof value === "string" &&
                    value.includes(`metadata[${x}].vehicleImages`)
                  ) {
                    continue;
                  }

                  if (Array.isArray(value)) {
                    value.forEach((itemValue, index) => {
                      formData.append(
                        `vehicleDetails[${key}][${x}][${index}]`,
                        itemValue as string | Blob
                      );
                    });
                  }
                } else if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", "MC");
            formData.append("businessClass", "MOT");
            formData.append("underwriter", values["underwriter"]);
            // return new Response(formData).text().then(console.log);

            if (formData.get("underwriter") == "") {
              variables.toastAlert(
                "Kindly select an underwriter before continuing with this action.",
                "success"
              );
            }

            ApiService.setHeader();
            ApiService.post(variables.CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                console.clear();
                console.log(response);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
                // router.push({ name: "insurance-packages-cart" });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    // Function to get underwriters
    const getUnderwriters = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.MOTOR_UNDERWRITERS_ROUTE}/MC`)
          .then(({ data }) => {
            //Assign data to form fields
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getVehicles = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/motorcycle`)
          .then(({ data }) => {
            vehicleBrands.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      getUnderwriters();
      getVehicles();

      setCurrentPageBreadcrumbs("Truck", [
        "Insurance Packages",
        "Motor Insurance",
        "Quote",
      ]);
    });

    return {
      submitButton1,
      saveComprehensive,
      metadataValidator,
      userType,
      underwriters,
      vehicleBrands,
    };
  },
  created() {
    //Set page title
    document.title = "Private Motor Insurance | " + process.env.VUE_APP_TITLE;
    this.userRole = variables.COOPERATIVE_ADMIN_USER_ROLE;
  },
});
