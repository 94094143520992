
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import variables from "@/router/api";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import VehicleRegAndChassisNumber from "@/components/reusable/VehicleRegAndChassisNumber.vue";
import VehicleUsage from "@/components/reusable/VehicleUsage.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import Insurer from "@/components/reusable/Insurer.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import "viewerjs/dist/viewer.css";
import $ from "jquery";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ResusableFunctions from "@/composables/ReusableFunctions";
import MotorcycleCategories from "@/views/reusable/buy-insurance/motor-insurance/motorcycle-categories.json";
import MotorcycleUses from "@/views/reusable/buy-insurance/motor-insurance/motorcycle-uses.json";
import MotorcycleGaraging from "@/views/reusable/buy-insurance/motor-insurance/motorcycle-garaging.json";

export default defineComponent({
  name: "motor-comprehensive-component",
  components: {
    Field,
    ErrorMessage,
    VehicleMakeAndModel,
    VehicleRegAndChassisNumber,
    ImagesUploader,
    Insurer,
    VehicleUsage,
  },
  props: {
    insurancePackage: String,
    underwriters: Array,
    bodyTypes: Array,
    // covers: Array,
  },
  data() {
    return {
      motorcycleCategories: MotorcycleCategories,
      motorcycleUses: MotorcycleUses,
      motorcycleGaraging: MotorcycleGaraging,
      showForm: false,
      covers: [],
      colorChart: ["/media/packages/vehicle-color-chart.jpeg"],
      images: [
        "/media/packages/car-body-types-1.jpeg",
        "/media/packages/car-body-types-2.jpeg",
      ],
      rates: {
        comprehensiveMotorRate: "",
      },
      metadata: [
        {
          vehicleOwner: "",
          package: "",
          vehicleValue: "",
          vehiclePremium: 0.0 as number,
          vehicleMake: "",
          vehicleModel: "",
          vehicleRegistrationNumber: "",
          chassisNumber: "",
          engineNumber: "",
          engineCapacity: "",
          yearManufactured: "",
          vehicleColor: "",
          bodyType: "",
          vehicleLicense: "",
          vehicleImages: "",
        },
      ],
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      insurancePackageType: this.insurancePackage,
      businessClass: "MOT",
      vehicleColors: VehicleColors,
    };
  },
  setup() {
    const { formatNumber, unformatNumber } = ResusableFunctions();
    return { formatNumber, unformatNumber };
  },
  methods: {
    premium(event, position) {
      // Add comma and allow decimal point vehicle value field
      variables.numberFormatter(event.target.id);
      const newValue = event.target.value;

      // Remove comma from vehicle value
      const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );

        // Call vehicle premium calculator
        this.vehiclePremiumCalculator(
          event.target.ariaValueNow,
          value,
          position
        );
      } else {
        return;
      }

      // Append total premium value tot total premium field
      this.totalPremium = variables.totalPremiumValue();

      // Append total sum insured value tot total sum insured field
      this.totalSumInsured = variables.totalSumInsured(
        value,
        event.target.title
      );

      // return this.$nextTick(() => (event.target.value = newValue));
    },
    addDynamicField() {
      this.metadata.push({
        vehicleOwner: "",
        package: "",
        vehicleValue: "",
        vehiclePremium: 0.0 as number,
        vehicleMake: "",
        vehicleModel: "",
        vehicleRegistrationNumber: "",
        chassisNumber: "",
        engineNumber: "",
        engineCapacity: "",
        yearManufactured: "",
        vehicleColor: "",
        bodyType: "",
        vehicleLicense: "",
        vehicleImages: "",
      });

      variables.toastAlert("Row added", "success");
    },
    removeDynamicField(index) {
      // Remove dynamic field
      $(".remove-" + index).remove();
      variables.toastAlert("Row removed", "success");

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    removeField(index) {
      this.metadata.splice(index, 1);
    },
    vehicleLicenseSelected(event, position) {
      this.metadata[position].vehicleLicense = event.target.files[0];
    },
    vehicleImagesSelected(event, position) {
      this.metadata[position].vehicleImages = event.target.files;
    },
    showBodyTypes() {
      this.$viewerApi({
        images: this.images,
      });
    },
    showColorChart() {
      this.$viewerApi({
        images: this.colorChart,
      });
    },
    vehiclePremiumCalculator(rate: number, value: number, position: number) {
      this.metadata[position].vehiclePremium = +variables.vehiclePremium(
        rate,
        value
      );
    },
    calculateTotal(event, position) {
      // Add comma and allow decimal point vehicle value field
      // variables.numberFormatter(event.target.id);
      // const newValue = event.target.value;

      const inputValue =
        parseFloat(this.unformatNumber(event.target.value)) || 0;

      // Call getPremium only when the length is greater than 5
      if (event.target.value.length > 6) {
        const underwriter = document.getElementById(
          "underwriter"
        ) as HTMLInputElement;
        const packageId = document.getElementById(
          "cover-id-" + position
        ) as HTMLInputElement;

        this.getPremium(
          underwriter.value,
          packageId.value,
          inputValue,
          event,
          position
        );
      }

      // return this.$nextTick(() => (event.target.value = newValue));

      // const initialAmount = this.unformatNumber(event.target.value);
      // const initialAmount = event.target.value;

      // // Convert to string if it's a number
      // const initialAmountString =
      //   typeof initialAmount === "number"
      //     ? initialAmount.toString()
      //     : initialAmount;

      // // Remove commas and then format with commas for thousands
      // const formattedAmount = initialAmountString
      //   .replace(/,/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     event.target.value = formattedAmount;
      //   });
      // }, 0.1);
    },
    getPremium(underwriter, packageId, sumInsured, event, position) {
      const payload = {
        businessClass: "MOT",
        underwriter: underwriter,
        package: packageId,
        sumInsured: sumInsured,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(variables.PREMIUM_ROUTE, payload)
          .then(({ data }) => {
            variables.premiumCalculator(
              event.target.ariaValueNow,
              data.data.grossPremium,
              event.target.ariaValueText
            );

            // Call vehicle premium calculator
            this.vehiclePremiumCalculator(
              event.target.ariaValueNow,
              data.data.grossPremium,
              position
            );

            // Append total premium value tot total premium field
            this.totalPremium = variables.totalPremiumValue();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }

      this.totalSumInsured = variables.totalSumInsured(
        sumInsured,
        event.target.title
      );
    },
    handleArrayFromChild(arrayFromChild) {
      this.showForm = true;
      this.covers = arrayFromChild;
    },
    // formatNumber(value): string {
    //   if (value.length > 0) {
    //     return parseFloat(value.replace(/[^\d.]/g, "")).toLocaleString(
    //       "en-US",
    //       {
    //         // minimumFractionDigits: 2,
    //         // maximumFractionDigits: 2,
    //       }
    //     );
    //   }

    //   return "";
    // },
  },
});
